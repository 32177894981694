import * as base from "./lib/baseActions";
import axios from "axios";
import _keys from "lodash.keys";


export interface UploadFileType {
  key: string;
  uploaded: boolean;
  name: string;
  file: File;
}

export interface UploadFileResultType {
  fileId: number;
  url: string;
  fileName: string;
}

const uploadFiles = async (uploadGroupId: number, files: Array<UploadFileType>) => {
 
  return Promise.all(files.map(file => uploadFile(uploadGroupId, file) ))
    .catch(() => {
      console.log('failed to upload Report images');
      throw new Error('failed to upload Report images');
    });	
};



const uploadFile = async (uploadGroupId: number, file: UploadFileType ):   Promise<UploadFileResultType> => {
  try {	  
    const postFormData = {
      fileName: file.file.name,
      fileType: file.file.type,
      fileSize: file.file.size,
      name: file.name
    };

    const postResult = await base.post( `upload/${uploadGroupId}/uploadfile`,  postFormData);
	
    const resultData = postResult.data;
    const fileId = resultData.id;
    const signedRequest = resultData.signedRequest;
      
    await putFile(signedRequest, file.file);

    const putResult = await base.put(`upload/${uploadGroupId}/uploadfile/${fileId}`, { uploaded: true } );

    return {
      fileId: fileId,
      url: putResult && putResult.data && putResult.data.url,
      fileName: file.name
    };
  }
  catch(err) {
    console.log('failed to upload file:', err);
    throw new Error(`failed to upload file`);
  }
};



const getLatestFile = async (uploadGroupId: number): Promise<UploadFileResultType> => {
  try {	   
    const result = await base.get(`upload/${uploadGroupId}/latestuploadfile`);
    const data = result.data;
 
    return {
      fileId: data.id,
      url: data.url,
      fileName: data.filename
    };
  }
  catch(err) {
    console.log('failed to get uploaed file:', err);
    throw new Error(`failed to upload file`);
  } 
};



/// post file data to endpoint
// Do not use the Bearer base Action endpoints
const putFile = (endpoint : string, data: any) => {
  const formData = new FormData();
  const keys = _keys(data);

  keys.forEach((k) => {
    formData.append(k, data[k]);
  });

  return axios.put(endpoint, data, getConfig({'content-type': 'multipart/form-data'}));

};


const getConfig = (overrideParams = {}) => {
  return {
    ...overrideParams
  };
};


export {
  uploadFiles,
  getLatestFile
};

