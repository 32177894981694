import React from 'react';

import { useAppState, useSetAppState } from 'context';
import { Button, LinkButton } from 'components/button/button';
import { logoutUser } from 'actions/userActions';

/// stateless Component.
/// If we need some presentation state (Forms, etc), use React.Component
const Nav = () => {
  // Nav for all pages
  const appState = useAppState();
  const setAppState = useSetAppState();

  const onLogout = async () => {
    await logoutUser();
      
    setAppState({ Authenticated: false, Authenticating: false });
  };

  return (
    <>
      { appState && appState.Authenticated
        ? (
          <Button onClick={onLogout}>Logout</Button>
        ) : (
          <LinkButton to="/login"> Login </LinkButton>
        )
      }
    </>
  );
};

export default Nav;
