import { ReactNode } from "react";
import { Link } from 'react-router-dom';
import classNames from "classnames";

import styles from "./button.module.scss";

interface IButtonProps {
  children?: ReactNode | string;
  style?: "primary" | "secondary" | "none";
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: "submit" | "button" | "reset" | undefined;
}

interface LinkButtonProps extends IButtonProps {
  to: string;
}

export const LinkButton = ({
  to,
  style= "primary",
  children,
  className,
}: LinkButtonProps) => {
  return (
    <Link
      to={to}
      className={classNames(styles.button, styles.linkButton, style && styles[style], className)}
    >
      {children}
    </Link>
  );
};

export const Button = ({
  style = "primary",
  children,
  className,
  onClick = () => undefined,
  disabled = false,
  type="button"
}: IButtonProps) => {
  if (style === 'none') {
    return (<div
      className={className}
      tabIndex={0}
      role="button"
      {...{
        disabled,
        onClick,
        onKeyPress: onClick,
        type
      }}
    >
      {children}
    </div>);
  }

  return (
    <button
      className={classNames(styles.button, style && styles[style], className)}
      {...{
        disabled,
        onClick,
        type
      }}
    >
      {children}
    </button>
  );
};
