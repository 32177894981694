import styles from './encryptedContent.module.scss';
import {createEncryptedContent, deleteEncryptedContent, useGetEncryptedContent, updateEncryptedContent} from 'actions/encryptedContentActions';
import { useState } from "react";
import classNames from "classnames";
import Layout from 'components/layout/Layout';
import { SubmitHandler } from "react-hook-form";
import { CreateEncryptedContentFormComponent } from 'components/createEncryptedContentForm/createEncryptedContentForm';

export interface EncryptedContentFormFields {
  id: number;
  title: string;
  text: string;
}


export interface contentItem {
  title: string;
  text: string;
  id: number;
}

export const EncryptedContent = () => {
  const [create, setCreate] = useState<boolean>(false);
  const [view, setView] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [selectedContent, setSelectedContent] = useState<EncryptedContentFormFields>({id: -1, title: "", text: ""});

  const content = useGetEncryptedContent();

  function toggleCreate() {
    setCreate(!create);
  }
  function openEdit(content: any) {
    setSelectedContent(content);
    setEdit(!edit);
  }

  function closeEdit() {
    setEdit(!edit);
  }

  function openView(content: any) {
    setSelectedContent(content);
    setView(!view);
  }

  function closeView() {
    setView(!view);
  }
  
  function openDelete(content: any) {
    setSelectedContent(content);
    setIsDelete(!isDelete);
  }
  function closeDelete() {
    setIsDelete(!isDelete);
  }

  const saveContent: SubmitHandler<EncryptedContentFormFields> = async (data) => {
    const { title, text } = data;
    await createEncryptedContent(title, text);
    toggleCreate();
  };

  const editContent: SubmitHandler<EncryptedContentFormFields> = async (data) => {
    const { title, text } = data;
    await updateEncryptedContent(selectedContent.id, title, text);
    closeEdit();
  };

  const deleteContent = async () => {
    await deleteEncryptedContent(selectedContent.id);
    closeDelete();
  };

  //Take the already uploaded user data and display in each row of table
  const tableOutput = content?.data?.map((item: any) => {
    const out =
    <tr>
      <td>{item.title}</td>
      <td>{item.createdAt}</td>
      <td>{item.updatedAt}</td>
      <td><button className={classNames(styles.tableBtn)} onClick={() => openView(item)}>view</button></td>
      <td><button className={classNames(styles.tableBtn)} onClick={() => openEdit(item)}>edit</button></td>
      <td><button className={classNames(styles.tableBtn)} onClick={() => openDelete(item)}>delete</button></td>
    </tr>;
    return out;
  });
 
  return (
    <Layout>
      <div className="App">
        <header className="App-header">
          <h2>Encrypted Content</h2>
          <body className="body">
            <button className={classNames(styles["open-button"])} onClick={toggleCreate}><h3>Create New</h3></button>
            <div className={classNames(styles["form-popup"], create && styles.block)}>
              <h3>Create New Content</h3>
              <CreateEncryptedContentFormComponent isEditable={true} onSubmit={saveContent} onClose={toggleCreate}/>
            </div>

            <div className={classNames(styles["form-popup"], view && styles.block)}>
              <h3>View Content</h3>
              <CreateEncryptedContentFormComponent isEditable={false} onClose={closeView} title={selectedContent.title} text={selectedContent.text}/>
            </div>
          
            <div className={classNames(styles["form-popup"], edit && styles.block)}>
              <h3>Edit Content</h3>
              <CreateEncryptedContentFormComponent isEditable={true} onSubmit={editContent} onClose={closeEdit} title={selectedContent.title} text={selectedContent.text}/>
            </div>
            <div className={classNames(styles["form-popup"], isDelete && styles.block)}>
              <h3>Delete Content</h3>
              <CreateEncryptedContentFormComponent isEditable={false} onSubmit={deleteContent} onClose={closeDelete} title={selectedContent.title} text={selectedContent.text}/>
            </div>
          </body>

          <div className={styles.table}>
            <table>
              <tr>
                <th>  Title  </th>
                <th>Time Created</th>
                <th>Last Updated</th>
                <th>View</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>      
              {tableOutput}
            </table>
          </div>
        </header>
      </div>
    </Layout>
  );
};
