import { FC, useEffect, useState } from "react";
import { AppProvider, useSetAppState, useSetUserState } from 'context/index';
import { Routing } from './routing/routing';
import { fetchUser, userIsAuthorized } from 'actions/userActions';
import useInterval from "use-interval";

import "./app.scss";
import Logging from "./Logging";

const useGetAuth = async () => {
  const setAppState = useSetAppState();
  const setUserState = useSetUserState();
  const [currentAuth2, setCurrentAuth2] = useState(false);


  useInterval(() => {
    const userIsAuth = userIsAuthorized();
    if(userIsAuth !== currentAuth2) {
      setCurrentAuth2(userIsAuth);
    }
  }, 1000);

  useEffect(() => {
    setAppState({ Authenticated: currentAuth2, Authenticating: false });
  }, [currentAuth2, setAppState]);

  useEffect(() => {
    const fetchData = async () => {
      const currentAuth = userIsAuthorized();

      setAppState({ Authenticated: currentAuth, Authenticating: true });

      try {
        const result = await fetchUser();
        
        if(result) {
          setUserState(result);
          setAppState({ Authenticated: currentAuth, Authenticating: false });
        }
      } catch(err) {
        setAppState({ Authenticated: false, Authenticating: false });
      }
    };

    fetchData();
  }, [setAppState, setUserState]);
};

const App: FC = () => {
  // update User Info
  useGetAuth();

  return (
    <>
      <Logging>
        <AppProvider>
          <Routing />
        </AppProvider>
      </Logging>
    </>
  );
};

export default App;
