import { FC } from 'react';
import { loginUser, userIsAuthorized } from 'actions/userActions';
import { useSetAppState, useAppState, useMergeAppState, useSetUserState } from 'context';
import { withRouter, useHistory } from 'react-router-dom';
import { Button } from 'components/button/button';
import { TextField } from 'components/textField/textField';
import styles from './loginForm.module.scss';
import { RoutePaths } from 'app/routing/routing';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { captchaSiteKey } from "../../config/config";
import { useForm, SubmitHandler } from "react-hook-form";

const useOnLogin = () => {
  const setUserState = useSetUserState();
  const setAppState = useSetAppState();
  const mergeAppState = useMergeAppState();

  const onSubmit: SubmitHandler<LoginFormFields> = async (data) => {
    mergeAppState({ Authenticating: true });
    const { email, password, captchaToken } = data;
    try {
      const results = await loginUser({email, password, captchaToken});

      if(results) {
        const { email, firstName, lastName } = results;
        setUserState({ email, firstName, lastName });
      }
  
      setAppState({ Authenticated: userIsAuthorized(), Authenticating: false });
        
      return results;
    } catch(err) {
      setAppState({ Authenticated: false, Authenticating: false });
    }
  };

  return onSubmit;
};

export interface LoginFormFields {
  email: string;
  password: string;
  captchaToken: string;
}

export const LoginFormComponent : FC = () => {
  const { register, handleSubmit, setValue, formState: { errors } } = useForm<LoginFormFields>();
  const appState = useAppState();
  const history = useHistory();

  const onLogin = useOnLogin();

  const onSubmit = async (values: LoginFormFields) => {
    if (!appState.Authenticated) {
      try {
        await onLogin(values);
      } catch (err) {
        console.log(err);
      }
    }
    history.push(RoutePaths.Home);

  };
  
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TextField register={register} type="email" name="email" placeholder="Email" label="Email" className={styles.field} labelClassName={styles.labels} errors={errors} validationSchema={{ 
        required: "Email is required",
        pattern: {
          value: /\S+@\S+\.\S+/,
          message: "Entered value does not match email format",
        }
      }} />
      <TextField register={register} type="password" name="password" placeholder="Password" label="Password" className={styles.field} labelClassName={styles.labels}  errors={errors} validationSchema={{ 
        required: "Password is required",
      }} />
      <Button className={styles.submitButton} type="submit">
              Submit
      </Button>
      {captchaSiteKey && (
        <HCaptcha 
          sitekey={captchaSiteKey}
          onVerify={(token) => { setValue("captchaToken", token); }}
        />
      )}
    </form>
  );
};

export const LoginForm = withRouter(LoginFormComponent);
