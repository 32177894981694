import { useState } from 'react';
import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest } from "../../config/msalConfig";
import { callMsGraph } from "actions/msalActions";
import { Button } from 'components/button/button';


interface GraphData {
  jobTitle: string;
  mail: string;
  businessPhones: string[];
  officeLocation: string;
  // Add other properties as needed
}

export const ProfileContent = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [graphData, setGraphData] = useState<GraphData | null>(null);

  function RequestProfileData() {
    if (account) {
      instance.acquireTokenSilent({
        ...loginRequest,
        account: account
      }).then((response) => {
        callMsGraph(response.accessToken).then(response => setGraphData(response));
      });
    }
  }
  

  return (
    <>
      <h5 className="card-title">Welcome {account ? account.name : "unknown"}</h5>
      {graphData ? 
        <div id="profile-div">
          <p><strong>Title: </strong> {graphData?.jobTitle}</p>
          <p><strong>Mail: </strong> {graphData?.mail}</p>
          <p><strong>Phone: </strong> {graphData?.businessPhones[0]}</p>
          <p><strong>Location: </strong> {graphData?.officeLocation}</p>
        </div>
        :
        <Button  onClick={RequestProfileData}>Request Profile Information</Button>
      }
    </>
  );
};