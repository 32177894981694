import { FC } from 'react';
import TextField from 'components/textField/textField';
import { Button } from 'components/button/button';
import { SubmitHandler, useForm } from "react-hook-form";
import classNames from "classnames";
import styles from './createEncryptedContentForm.module.scss';

export interface CreateEncryptedContentFormFields {
  isEditable: boolean;
  onClose: () => void;
  onSubmit?: SubmitHandler<any>;
  title?: string;
  text?: string;
}

export const CreateEncryptedContentFormComponent : FC<CreateEncryptedContentFormFields> = ({isEditable, onSubmit, onClose, title, text}) => {
  const { register, handleSubmit, getValues, setValue, formState: { errors } } = useForm<CreateEncryptedContentFormFields>();

  if (title && text){
    setValue(`title`, title);
    setValue(`text`, text);
  }

  const titleField = isEditable ? 
    <TextField register={register} type="text" name="title" placeholder="Title" label="Title" className={styles.field} labelClassName={styles.labels} /> 
    : <>
      <label htmlFor="title"><b>Title</b></label>
      <p>{getValues('title')}</p> 
    </>;

  const textField = isEditable ? 
    <TextField register={register} type="text" name="text" placeholder="Enter Text" label="Text" className={styles.field} labelClassName={styles.labels} />
    : <>
      <label htmlFor="text"><b>Text</b></label>
      <p>{getValues('text')}</p>
    </>;

  const form = onSubmit ?
    <form onSubmit={handleSubmit(onSubmit)} className={classNames(styles["form-container"])}>
      {titleField}
      {textField}
      <Button className={styles.submitButton} type="submit">Submit</Button>
      <Button className={styles.submitButton} onClick={onClose}>Close</Button>     
    </form>
    :
    <form className={classNames(styles["form-container"])}>
      {titleField}
      {textField}
      <Button className={styles.submitButton} onClick={onClose}>Close</Button>     
    </form>;

  return form;
};
