import { FC } from 'react';

import Header from "../header/header";
import Footer from "../footer/footer";
import ContentWrapper from "../contentWrapper/contentWrapper";

const Layout : FC = ({children}) => {
  return (<div>
    <Header/>
    <ContentWrapper>
      {children}
    </ContentWrapper>
    <Footer/>
  </div>);
};

export default Layout;
