import { ReactNode } from "react";
import s from "./infoBlock.module.scss";
import cn from "clsx";

interface InfoBlockProps {
  id?: string;
  title: string;
  subtitle: string;
  children?: ReactNode;
  fullWidth?: boolean;
}

export const InfoBlock = ({
  id,
  title,
  subtitle,
  fullWidth,
  children,
}: InfoBlockProps) => {
  return (
    <div id={id} className={cn(s.infoBlock, fullWidth && s.fullWidth)}>
      <h2 className={s.title}>{title}</h2>
      <p className={s.subtitle}>{subtitle}</p>
      <div className={s.children}>{children}</div>
    </div>
  );
};
