interface Item {
  order: number;
}

export const invalidEmail = (email: string) => {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
};

export const convertYesNoToBool = (selected: string) => {
  return selected === "Yes" ? true : false;
};

export const isUndefined = (input: boolean | undefined) => {
  return input === undefined;
};

export const sortByOrder = (a: Item, b: Item) => { 
  return a.order < b.order ? -1 : a.order > b.order ? 1 : 0; 
};

export const openInNewTab = (url: string | undefined): void => {
  if (!url) return;
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};
