import React from "react";
import s from "./heroBanner.module.scss";
import { ReactComponent as BannerImage } from "assets/svgs/banner-image.svg";
import { SimpleDropdown } from "components/dropdown/dropdown";

export const HeroBanner = () => {

  const options = [
    {
      option: <a href="#tipsAndtricks">Tips and Tricks</a>,
    },
    {
      option: <a href="#scssPatterns">SCSS Patterns</a>,
    },
    {
      option: <a href="#htmlElements">HTML Elements and Behaviour</a>,
    },
    {
      option: <a href="#mobileStyling">Mobile Styling</a>,
    },
    {
      option: <a href="#layeringImages">Layering Images</a>,
    }
  ];

  return (
    <div className={s.heroBanner}>
      <div className={s.bannerContent}>
        <div className={s.textContent}>
          <h1>A Guide to Styling</h1>
          <SimpleDropdown title={`Select a section`} options={options} />
        </div>
        <BannerImage className={s.bannerImage} />
      </div>
    </div>
  );
};
