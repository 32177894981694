import { FC } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { Home } from 'pages/home/home';
import { Login } from "pages/login/login";
import { SignUp } from "pages/signUp/signUp";
import { Upload } from "pages/upload/upload";
import { EncryptedContent } from "pages/encryptedContent/encryptedContent";
import { ActiveDirectorySSO } from "pages/activeDirectorySSO/activeDirectorySSO";
import { useAppState } from 'context';
import { StyleGuide } from "pages/styleGuide/styleGuide";
import { UserPage } from "pages/userPage/userPage";
import { EditUser } from "pages/editUser/editUser";


export enum RoutePaths {
  Home = "/",
  UserPage = "/user",
  EditUser = "/user-edit",
  StyleGuide = "/style-guide",
  Login = "/login",
  SignUp = "/signUp",
  Upload = "/upload", 
  EncryptedContent = "/encryptedContent",
  ActiveDirectorySSO = "/adsso"  
}

const AuthenticatedRoutes = () => {
  return (
    <Switch>
      <Route path={RoutePaths.UserPage}>
        <UserPage />
      </Route>
      <Route path={RoutePaths.EditUser}>
        <EditUser/>
      </Route>
      <Route path={RoutePaths.EncryptedContent}>
        <EncryptedContent />
      </Route>
      <Route path={RoutePaths.Upload}>
        <Upload />
      </Route>
      <Route path={RoutePaths.Home}>
        <Home />
      </Route>
    </Switch>
  );
};

export const Routing : FC = () => {
  const appState = useAppState();

  return (
    <Router>
      <Switch>
        <Route path={RoutePaths.StyleGuide}>
          <StyleGuide />
        </Route>
        <Route path={RoutePaths.SignUp}>
          <SignUp />
        </Route>
        <Route path={RoutePaths.Login}>
          <Login />
        </Route>
        <Route path={RoutePaths.ActiveDirectorySSO}>
          <ActiveDirectorySSO />
        </Route>
        <Route
          render={({ location }) => {
            if(appState.Authenticating && !appState.Authenticated) {
              return <>null</>;
            } else if (appState.Authenticated) {
              return <AuthenticatedRoutes />;
            } else {
              return <Redirect
                to={{
                  pathname: RoutePaths.Login,
                  state: { from: location }
                }}
              />;
            }
          }}
        />
      </Switch>
    </Router>
  );
};
