import React, { ReactNode, useEffect } from 'react';
import {LOG_TYPES, log} from 'actions/logActions';
import { logzioEnabled } from 'config/config';

interface LoggingProps {
  children?: ReactNode;
}


function Logging({children}: LoggingProps) {
  
  useEffect(() => {
    if (!logzioEnabled) return;
    // Log if throw is use
    const handleError = (error: any) => {
      log({type: LOG_TYPES.ERROR, data: error.error});
    };
   
    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);


  useEffect(() => {
    // Log if an console.error is used
    if (!logzioEnabled) return;

    const originalConsoleError = console.error;
    console.error = function (error) {
      originalConsoleError(error);
     
      log({type: LOG_TYPES.ERROR, data: error});
    };

    return () => {
      console.error = originalConsoleError;
    };
  }, []);


  useEffect(() => {
    // Log if an console.log is used
    if (!logzioEnabled) return;
   
    const originalConsoleLog = console.log;
    console.log = function (data) {
      originalConsoleLog(data);
      log({type: LOG_TYPES.LOG, data: data});
    };

    return () => {
      console.log = originalConsoleLog;
    };
  }, []);


  useEffect(() => {
    // Log if an console.info is used
    if (!logzioEnabled) return;
   
    const originalConsoleInfo = console.info;
    console.info = function (info) {
      originalConsoleInfo(info);
      log({type: LOG_TYPES.INFO, data: info});
    };

    return () => {
      console.info = originalConsoleInfo;
    };
  }, []);

  useEffect(() => {
    // Log if an console.warn is used
    if (!logzioEnabled) return;
   
    const originalConsoleWarn = console.warn;
    console.warn = function (warn) {
      originalConsoleWarn(warn);
      log({type: LOG_TYPES.INFO, data: warn});
    };

    return () => {
      console.warn = originalConsoleWarn;
    };
  }, []);
  



  return <>{children}</>;
}

export default Logging;