import { useState } from "react";
import s from "./styleGuide.module.scss";
import cn from "clsx";
import { InfoBlock } from "../../components/infoBlock/infoBlock";
import DemoImage from "assets/images/demo-image.png";
import DemoImage2 from "assets/images/demo-image2.png";
import {
  CSSStyleDoCS,
  CSSStyleDontCS,
  htmlFlexExCS1,
  htmlFlexExCS2,
  htmlFlexExCS3,
  htmlTagCS,
  htmlTagDontCS,
  mobileCSSDo,
  mobileCSSDont,
  nestCSSDo,
  nestCSSDont,
  variablesCS,
} from "./codeSnippets";
import TextField from "components/textField/textField";
import { useForm } from "react-hook-form";
import Layout from "components/layout/Layout";
import { Button } from "components/button/button";
import { HeroBanner } from "components/heroBanner/heroBanner";
import { ToggleBoolean } from "components/toggle/toggle";
import { RadioField, RadioFieldStyle } from "components/radioField/radioField";

export interface StyleGuideFormFields {
  isOutlineMode: boolean;
}

export const StyleGuide = () => {
  const { register, formState: { errors } } = useForm<StyleGuideFormFields>();
  const [isOutlineMode, setIsOutlineMode] = useState<boolean>(false);

  return (
    <Layout>
      <div
        className={cn(
          s.styleGuideMainContainer,
          isOutlineMode && s.outlineMode
        )}
      >
        <HeroBanner />
        <InfoBlock
          id={`tipsAndTricks`}
          title={`I. Before Starting`}
          subtitle={`Some tips and tricks before beginning styling:`}
        >
          <ToggleBoolean
            label={`Turn on Outline Mode`}
            onClick={(e) => {
              setIsOutlineMode(e as boolean);
            }}
            value={isOutlineMode}
            groupName={`outlineMode`}
          />
          <ul>
            <li>
              Elements are essentially laid out like stacks of boxes. Before
              starting on a layout, plan out your boxes - which are nested,
              which need to be their own boxes, etc.{" "}
              <span className={s.emphasize}>
                Try turning on Outline Mode to see how each element on this page
                is laid out.
              </span>
            </li>
            <li>
              When approaching a layout, also think of how you can{" "}
              <span className={s.emphasize}>keep the same HTML layout</span> in
              mobile. The easiest way to think about it is by thinking if you
              can{" "}
              <span className={s.emphasize}>
                stack each block on top of each other.
              </span>
            </li>
            <li>
              Don't try to force a layout. Elements should be able to flow on a
              page to accommodate responsiveness.
            </li>
            <li>
              Start with the elements and components that look the same. Like
              regular code, it is encouraged that you re-use your CSS. SASS
              offers something called{" "}
              <span className={s.emphasize}>mixins</span> to help with that.
            </li>
            <li>
              <p>
                <span className={s.emphasize}>
                  Uh oh - the project uses a styling framework!
                </span>{" "}
                You may come across frameworks such as Bootstrap or Tailwind.
                Proper usage of these frameworks means mostly, if not
                completely, using the classes they offer. You may be familiar
                with this format: <code>{`<div class"col-6 pt-8 m-64">`}</code>.
                What this means is you're specifying each CSS property you want
                inline using the framework's classes. Why do this? It would be
                as if you had written a file with all your shared styles except
                to the absolute most granular way. It may work with
                cookie-cutter personality-free sites, but will be trouble if you
                want to build something custom.
              </p>
            </li>
          </ul>
        </InfoBlock>
        <InfoBlock
          id={`scssPatterns`}
          title={`II. SCSS Patterns`}
          subtitle={`Patterns to keep in mind to ensure SCSS/CSS code is consistent across developers.`}
        >
          <h4>Shared variables</h4>
          <div className={s.sideBySide}>
            <div className={s.section}>
              <p>Examples from sizes.scss and colors.scss</p>
              <div className={s.codeContainer}>
                <code>{variablesCS}</code>
              </div>
            </div>
            <div className={s.section}>
              <ul>
                <li>
                  Global variables should exist in the base scss files. These
                  are:{" "}
                  <span className={s.emphasize}>
                    colours, shared sizes, spacing, mixins, etc.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <h4>Parent classes</h4>
          <div className={s.sideBySide}>
            <div className={s.section}>
              <p>
                <span className={s.emphasize}>DO:</span> Use a parent class for
                shared styles, and a secondary class for specific styles.
              </p>
              <div className={s.codeContainer}>
                <code>{CSSStyleDoCS}</code>
              </div>
            </div>
            <div className={s.section}>
              <p>
                <span className={s.emphasize}>DON'T:</span> Repeat styles. If
                something needs to be changed, you’ll have to change all of
                them.
              </p>
              <div className={s.codeContainer}>
                <code>{CSSStyleDontCS}</code>
              </div>
            </div>
          </div>
          <h4>Nesting CSS classes</h4>
          <div className={s.sideBySide}>
            <div className={s.section}>
              <p>
                <span className={s.emphasize}>DO:</span> Nest your CSS according
                to divs.
              </p>
              <div className={s.codeContainer}>
                <code>{nestCSSDo}</code>
              </div>
            </div>
            <div className={s.section}>
              <p>
                <span className={s.emphasize}>DON'T:</span> Not nest your CSS at
                all.
              </p>
              <div className={s.codeContainer}>
                <code>{nestCSSDont}</code>
              </div>
            </div>
          </div>
          <h5>
            <span className={s.emphasize}>Why?</span> If you nest your SCSS the
            same way you have your HTML nested, not only does it keep your
            styles organized and easy for the next developer to follow and find,
            it also follows the pattern of vanilla CSS and prevents cases where
            there is overlap for the same class names.
          </h5>
        </InfoBlock>
        <InfoBlock
          id={`htmlElements`}
          title={`III. HTML Elements and Behaviour`}
          subtitle={`Element use is consistent, and according to design guidelines, or UI library`}
        >
          <h4>Proper Tag Use</h4>
          <div className={s.sideBySide}>
            <div className={s.section}>
              <p>
                <span className={s.emphasize}>DO:</span> Use the appropriate
                tags
              </p>
              <div className={s.codeContainer}>
                <code>{htmlTagCS}</code>
              </div>
            </div>
            <div className={s.section}>
              <p>
                <span className={s.emphasize}>DON'T:</span> Style every instance
                of an element.
              </p>
              <div className={s.codeContainer}>
                <code>{htmlTagDontCS}</code>
              </div>
            </div>
          </div>
          <h4>Side-by-side Elements</h4>
          <div className={s.sideBySide}>
            <div className={s.section}>
              <p>html</p>
              <div className={s.codeContainer}>
                <code>{htmlFlexExCS1}</code>
              </div>
            </div>
            <div className={s.section}>
              <p>
                <span className={s.emphasize}>DO:</span> Use display: flex;
              </p>
              <div className={s.codeContainer}>
                <code>{htmlFlexExCS2}</code>
              </div>
            </div>
            <div className={s.section}>
              <p>
                <span className={s.emphasize}>DON'T:</span> Use display:
                inline-block;
              </p>
              <div className={s.codeContainer}>
                <code>{htmlFlexExCS3}</code>
              </div>
            </div>
          </div>
          <h5>
            <span className={s.emphasize}>Why?</span> Display flex assigns the
            behaviour to the <i>parent</i> for its children. It is ideal to
            write CSS in a way that a parent element can define the styles and
            behaviour of its children.
          </h5>
        </InfoBlock>
        <InfoBlock
          id={`mobileStyling`}
          title={`IV. Mobile Styling`}
          subtitle={``}
        >
          <h5>
            <span className={s.emphasize}>Tip:</span> As mentioned, see if you
            can stack your HTML elements on top of each other first.{" "}
            <span className={s.emphasize}>
              Your CSS should not force responsiveness.
            </span>{" "}
            The foundation of a responsive layout starts with how your HTML is
            structured.
          </h5>
          <div className={s.sideBySide}>
            <div className={s.section}>
              <p>
                <span className={s.emphasize}>DO:</span> Group your CSS into
                media queries for that breakpoint.
              </p>
              <div className={s.codeContainer}>
                <code>{mobileCSSDo}</code>
              </div>
            </div>
            <div className={s.section}>
              <p>
                <span className={s.emphasize}>DON'T:</span> Add media queries
                per class.
              </p>
              <div className={s.codeContainer}>
                <code>{mobileCSSDont}</code>
              </div>
            </div>
          </div>
          <h5>
            <span className={s.emphasize}>Why?</span> Grouping your CSS into a
            single media query for a particular breakpoint gives you an overview
            of what your layout would look like for that breakpoint rather than
            have to keep track of each media query per class.
          </h5>
          <h5>
            <span className={s.emphasize}>Tip:</span> Take note of the border
            property on the .content class. You should only be adding CSS for
            properties that{" "}
            <span className={s.emphasize}>change per breakpoint</span>. You are
            not styling a new layout per breakpoint. Start with a base (usually
            mobile because the CSS tends to be simpler), and change only the
            necessary properties.
          </h5>
        </InfoBlock>
        <InfoBlock
          id={`layeringImages`}
          title={`V. Layering Images`}
          subtitle={``}
          fullWidth={true}
        >
          <div className={s.layeredImagesContainer}>
            <div className={s.demoImageContainer}>
              <div className={s.sideBySide}>
                <div className={s.section}>
                  <img
                    className={cn(s.demoImage, s.firstImage)}
                    src={DemoImage}
                  />
                  <img
                    className={cn(s.demoImage, s.secondImage)}
                    src={DemoImage2}
                  />
                </div>
                <div className={s.section}>
                  <ul className={s.layeredImageList}>
                    <li>
                      When using{" "}
                      <span className={s.emphasize}>position:absolute</span>,
                      always be mindful of where your{" "}
                      <span className={s.emphasize}>position:relative</span>{" "}
                      will be. Most of the time, you will want it on the direct
                      parent.
                    </li>
                    <li>
                      For adding overlays or a secondary background (such as an
                      overlay on top of a Hero Banner, or a modal background),
                      utilize the pseudo-elements:{" "}
                      <span className={s.emphasize}>::before</span> and{" "}
                      <span className={s.emphasize}>::after</span>. These will
                      need the CSS property{" "}
                      <span className={s.emphasize}>content: '';</span> to show.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </InfoBlock>
        <InfoBlock id={`uiElements`} title={`VI. UI Elements`} subtitle={``}>
          <div className={s.uiElementsGrid}>
            <div className={s.uiInput}>
              <RadioField
                style={RadioFieldStyle.StandardCircle}
                text={"Radio Field"}
                value={""}
                groupName={""}
              />
            </div>
            <div className={s.uiInput}>
              <ToggleBoolean groupName={""} />
            </div>
            <div className={s.uiInput}>
              <Button>Button</Button>
            </div>
            <div className={s.uiInput}>
              <form>
                <TextField
                  register={register}
                  type="email"
                  name="email"
                  placeholder="Email"
                  label="Email"
                  errors={errors} 
                  validationSchema={{ 
                    required: "Email is required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    }
                  }}
                />
              </form>
            </div>
          </div>
        </InfoBlock>
      </div>
    </Layout>
  );
};
