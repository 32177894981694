import React from 'react';
import { LoginForm } from '../../components/loginForm/loginForm';
import { useHistory } from "react-router-dom";
import { RoutePaths } from "app/routing/routing";
import s from "./login.module.scss";
import Layout from 'components/layout/Layout';
import { Button } from 'components/button/button';

export const Login = () => {
  const history = useHistory();

  const onSignUpButtonClick = () => { 
    history.push(RoutePaths.SignUp); 
  };
  
  const onADLoginButtonClick = () => { 
    history.push(RoutePaths.ActiveDirectorySSO); 
  };


  return (
    <Layout>
      <div className={s.content} >
        <h1>Hello, and welcome to Topsoil</h1>
        <div>
          <h3>Please Log in</h3>
          <LoginForm />
        </div>
        <div>
          <p>Or</p>
          <Button type="button" onClick={onSignUpButtonClick} style="secondary">
              Sign Up
          </Button>
          <Button type="button" onClick={onADLoginButtonClick} style="secondary">
            Active Directory Login
          </Button>
        </div>
      </div>
    </Layout>
  );
};
