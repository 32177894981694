import { FC } from 'react';
import classNames from 'classnames';
import styles from './textField.module.scss';
import { FieldErrors, UseFormRegister } from 'react-hook-form';

interface TextFieldProps {
  name: string;
  type: 'email' | 'password' | 'text' | 'textArea';
  register: UseFormRegister<any>;
  errors? : FieldErrors<any>;
  validationSchema?: any;
  label?: string;
  required?: boolean;
  placeholder?: string;
  className?: string;
  labelClassName?: string;
  fieldClassName?: string;
}

export const TextField : FC<TextFieldProps> = ({name, label, required, type, placeholder, className, labelClassName, fieldClassName, register, errors, validationSchema }) => {
  return (
    <div className={className}>
      { (label || required) 
        && (
          <label htmlFor={name} className={classNames(styles.label, labelClassName)}>
            {label}
            {required && (<span className={styles.required}>&emsp;</span>)}
          </label>
        )
      }
      {type === 'textArea' ? 
        (<textarea {...register(name as `${string}`, validationSchema)} placeholder={placeholder} className={classNames(styles.field, fieldClassName)} />)
        : (<input {...register(name as `${string}`, validationSchema)} type={type} placeholder={placeholder} className={classNames(styles.field, fieldClassName)} />)
      }
      {errors && (
        <div>
          <span className={styles.alert}>{errors[name]?.message}</span>
        </div>
      )}
    </div>
  );
};

export default TextField;