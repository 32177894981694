import * as base from "./baseActions";
import { apiEndpoints } from "../../config/config";

const apiHealthCheck = async () => {
  try {
    const response = await base.get(apiEndpoints.healthCheckApi);
    return response;
  } catch (err) {
    throw new Error(`Failed to perform the API health check`);
  }
};

const databaseHealthCheck = async () => {
  try {
    const response = await base.get(apiEndpoints.healthCheckDatabase);
    return response;
  } catch (err) {
    throw new Error(`Failed to perform the database health check`);
  }
};

export {
  apiHealthCheck,
  databaseHealthCheck
};
