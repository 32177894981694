import * as base from "./lib/baseActions";
import { apiEndpoints } from "config/config";

export enum LOG_TYPES {
  ERROR = 'error',
  LOG = 'log',
  WARNING = ' warning',
  INFO = 'info'
}
type Log = {
  type: LOG_TYPES
  data: any
}

export const log = async (data: Log) : Promise<void> => {
  try {
    await base.post(apiEndpoints.logging, data);
  } catch (err) {
    // No logging here or there will be an inifinte loop
  }
};

