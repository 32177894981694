import cn from "clsx";
import React from "react";
import styles from "./radioField.module.scss";

export enum RadioFieldStyle {
  Pill,
  StandardCircle,
}

interface RadioFieldType {
  style?: RadioFieldStyle;
  text: string;
  value: string;
  groupName: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
}

export const RadioField = ({
  style = RadioFieldStyle.StandardCircle,
  value,
  text,
  groupName,
  checked,
  disabled = false,
  onChange,
  onClick,
}: RadioFieldType) => {
  return (
    <div className={styles.radioContainer}>
      <input
        className={cn(
          styles.radio,
          disabled && styles.disabled,
          style === RadioFieldStyle.StandardCircle && styles.circleRadio,
          style === RadioFieldStyle.Pill && styles.pill
        )}
        type="radio"
        id={`${value}Value-${groupName}`}
        name={groupName}
        checked={checked}
        value={value}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
      />
      <label htmlFor={`${value}Value-${groupName}`}>{text}</label>
    </div>
  );
};
