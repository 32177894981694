import * as base from "./lib/baseActions";
import { jwtData, apiEndpoints } from "../config/config";
import { UserFormFields } from "components/userForm/userForm";

/// Fetch data for logged in user
const fetchUser = async () => {
  try {
    const userInfo = await base.get(apiEndpoints.usersMe);
    return userInfo.data;
  }
  catch {
    return null;
  }
};

export interface loginUserData {
  email: string;
  password: string;
  passwordConfirmation?: string;
  captchaToken: string; 
}

export interface signupUserData {
  email: string;
  password: string;
  passwordConfirmation?: string; 
}

export interface authResult {
  data: {
    authToken: string;
    expiresIn?: number;
    refreshToken?: string;
    refreshExpiresIn?: number;
  }
}

export interface loginUserResult {
  data: {
    email?: string;
    firstName?: string;
    lastName?: string;
  };
}

export interface UserProfileData {
  email: string;
  role?: string;
  firstName?: string;
  lastName?: string;
  id: string;
}

/// Login
/// data = { email:"login email", password: "login pass" }
const loginUser = async (data : loginUserData) : Promise<UserProfileData> => {
  const { email, password, captchaToken } = data;

  try {
    const loginResult: authResult  = await base.post(apiEndpoints.login, {
      email,
      password,
      captchaToken
    });

    localStorage.setItem(jwtData.authToken, loginResult.data.authToken);
    localStorage.setItem(jwtData.authTokenExpiry, `${loginResult.data.expiresIn}`);
    const { refreshToken, refreshExpiresIn } = loginResult.data;

    if (refreshToken) {
      localStorage.setItem(jwtData.refreshToken, refreshToken);
    }

    if (refreshExpiresIn) {
      localStorage.setItem(jwtData.refreshTokenExpiry, `${refreshExpiresIn}`);
    }

    return await fetchUser();
  } catch (err: any) {

    if(err.status === 400) {
      err.message = "Incorrect Email or Password.";
    }

    throw new Error(`Incorrect Email or Password`);
  }
};

/// logout user
const logoutUser = async () => {
  localStorage.removeItem(jwtData.authToken);
  localStorage.removeItem(jwtData.refreshToken);
};

// create login user
// data : { email: "email@email.com", password:"password", confirmPassword:"password" }
const createUser = async (data: signupUserData) => {
  const registrationCheck = checkRegistrationInformation(data);

  if (!registrationCheck.success) {
    throw registrationCheck.errors;
  } else {
    try {
      const userResult = await base.post(apiEndpoints.users, data);
      return userResult;
    } catch (err: any) {
      throw err?.original?.response;
    }
  }
};

/// check confirmation helper function
const checkRegistrationInformation = (data : signupUserData) => {
  const check : { success: boolean; errors?: Error[] } ={ success: true};
  const errors = [];
  if(!data.email || data.email.length < 5) {
    // does not have email
    errors.push(new Error("You must fill in a valid email"));
  }

  if (!data.password || !data.passwordConfirmation) {
    errors.push(new Error("You must fill in the password and password confirmation field"));    
  } else if (data.password.length < 8) {
    errors.push(new Error("The password must be a minimum length of 8"));
  } else if (data.password !== data.passwordConfirmation) {
    errors.push(new Error("The password and confirmation password must match"));
  }

  if(errors.length > 0) {
    check.success=false;
    check.errors = errors;
  }

  return check;
};

// check if user is Authorized
const userIsAuthorized = () => {
  const initialAuthToken = localStorage && localStorage.getItem(jwtData.authToken);
  return initialAuthToken ? true : false;
};

const updateUser = async (userID: string, data: UserFormFields) => {
  const { firstName, lastName, email  } = data;
  // Only add if has a value
  const dataToSave = {
    ...firstName  && {firstName},
    ...lastName  && {lastName},
    ...email  && {email},
  };

  await base.put(`users/${userID}`, {
    ...dataToSave
  });

  return fetchUser();
};


export {
  fetchUser,
  loginUser,
  logoutUser,
  createUser,
  userIsAuthorized,
  updateUser,
};
