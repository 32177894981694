import React from "react";
import { ReactComponent as MainLogo } from "assets/svgs/Logo.svg";
import Layout from "components/layout/Layout";
import { Button } from "components/button/button";

export const Home = () => {

  return (
    <Layout>
      <div className="App">
        <header className="App-header">
          <MainLogo className="App-logo" />
          <p>Hello, and welcome to Topsoil</p>
          <Button>Press Me</Button>
        </header>
      </div>
    </Layout>
  );
};
