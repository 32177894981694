import React from "react";
import { SignUpForm } from '../../components/signUpForm/signUpForm';
import s from "./signUp.module.scss";
import Layout from "components/layout/Layout";

export const SignUp = () => {
  return (
    <Layout>
      <div className={s.content}>
        <h3>Topsoil Sign Up Page</h3>
        <SignUpForm />
      </div>
    </Layout>
  );
};
