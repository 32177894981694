import React from 'react';
import styles from './footer.module.scss';

// Footer for all pages
// Dumb component, so use stateless Function declaration
// no props
const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerText}>
        Topsoil
      </div>
      <div>
        Footer Text
      </div>
    </footer>
  );
};

export default Footer;
