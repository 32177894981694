import React from "react";
import { useEffect, useState } from "react";
import Nav from "./nav/nav";
import { ReactComponent as LogoSvg } from "assets/svgs/Logo.svg";
import styles from "./header.module.scss";
import { useUserState } from "context";
import { logoutUser, userIsAuthorized } from "actions/userActions";
import { useSetAppState } from "context";
import {
  apiHealthCheck,
  databaseHealthCheck,
} from "actions/lib/siteHealthCheckActions";
import { RoutePaths } from "app/routing/routing";
import { useHistory } from "react-router-dom";
import { SimpleDropdown } from "components/dropdown/dropdown";

// Header for all pages
// Dumb component, so use stateless Function declaration
// no props
const Header = () => {
  const userState = useUserState();
  let connectApi = "Disconnected";
  let connectData = "Disconnected";
  const history = useHistory();

  const [connectedToApi, setConnectedToApi] = useState(false);
  const [connectedToDatabase, setConnectedToDatabase] = useState(false);

  useEffect(() => {
    const checkApiConnection = async () => {
      try {
        const result = await apiHealthCheck();
        if (result.data.connected) {
          setConnectedToApi(true);
        }
      } catch (err) {
        setConnectedToApi(false);
      }
    };

    const checkDatabaseConnection = async () => {
      try {
        const result = await databaseHealthCheck();
        if (result.data.connected) {
          setConnectedToDatabase(true);
        }
      } catch (err) {
        setConnectedToDatabase(false);
      }
    };

    checkApiConnection();
    checkDatabaseConnection();
  }, []);

  const setAppState = useSetAppState();

  const onLogout = async () => {
    await logoutUser();

    setAppState({ Authenticated: false, Authenticating: false });
    history.push(RoutePaths.Login);
  };

  if (connectedToApi) {
    connectApi = "Connected";
  }
  if (connectedToDatabase) {
    connectData = "Connected";
  }

  const dropdownOptionsAuthenticated = [
    {
      option: (
        <a type="button" onClick={() => history.push(RoutePaths.StyleGuide)}>
          Style Guide
        </a>
      ),
    },
    {
      option: (
        <a type="button" onClick={() => history.push(RoutePaths.Upload)}>
          Upload
        </a>
      ),
    },
    {
      option: (
        <a
          type="button"
          onClick={() => history.push(RoutePaths.EncryptedContent)}
        >
          Encrypted Content
        </a>
      ),
    },
    {
      option: (
        <a
          type="button"
          onClick={() => history.push(RoutePaths.UserPage)}
        >
          User Page
        </a>
      ),
    },
    {
      option: (
        <a id="logout"  
          type="button"
          onClick={onLogout}>
          Logout
        </a>
      ),
    },
  ];

  const dropdownOptions = [
    {
      option: (
        <a type="button" onClick={() => history.push(RoutePaths.StyleGuide)}>
          Style Guide
        </a>
      ),
    },
    {
      option: (
        <a type="button" onClick={() => history.push(RoutePaths.SignUp)}>
          Signup
        </a>
      ),
    },
    {
      option: (
        <a type="button" onClick={() => history.push(RoutePaths.Login)}>
          Login
        </a>
      ),
    },
  ];

  return (
    <header>
      <div className={styles.header}>
        <LogoSvg className={styles.headerLogo} />
        <div>Topsoil</div>
        <div className={styles.healthChecks}>
          <h4>Api: {connectApi}</h4>
          <h4>Database: {connectData}</h4>
        </div>
        <SimpleDropdown
          title={`Navigation`}
          options={
            userIsAuthorized() ? dropdownOptionsAuthenticated : dropdownOptions
          }
        />
        <div className={styles.nav}>
          {`${(userState && userState.email) || " "} `}
          <Nav />
        </div>
      </div>
    </header>
  );
};

export default Header;
