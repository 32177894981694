import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useMsal} from "@azure/msal-react";
import { msalConfig } from "../../config/msalConfig";
import { signIn, signOut, forgotPassword } from "actions/msalActions";
import { ProfileContent } from "components/profileContent/profileContent";
import { Button } from "components/button/button";
import Layout from "components/layout/Layout";


export const SignInSignOutButtons = () => {
  const { instance } = useMsal();  

  return (
    <div>
      <AuthenticatedTemplate>
        <ProfileContent />
        <Button  onClick={() => signOut(instance)} >Sign Out</Button>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <h5>Please sign-in to see your profile information.</h5>         
        <Button onClick={() => { signIn(instance); }} >Sign In</Button>
        <Button onClick={() => { forgotPassword(instance); }} >ForgotPassword</Button>
      </UnauthenticatedTemplate>  
    </div>
  );
};


export const ActiveDirectorySSO = () => {
    
  const msalInstance = new PublicClientApplication(msalConfig);
  
  return (
    <MsalProvider instance={msalInstance}> 
      <Layout>
        <div>
          <p>Topsoil Active Directory Page</p>
          <SignInSignOutButtons/>
        </div>
      </Layout>
    </MsalProvider>    
  );
};
