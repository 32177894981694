export const variablesCS = `$padding-small: 0.3125rem;
$padding-medium: 0.9375rem;
$padding-large: 1rem;

$margin-small: 0.625rem;
$margin-medium: 1.875rem;
$margin-large: 4.063rem;

// Brand colours
$brand-teal: #00505C;
$brand-aqua: #4BDBC3;`;

export const htmlTagCS = `<h1>This is a large header</h1>

h1, h2, h3, h4, h5, h6 {
 font-family: “Helvetica”;
}

h1 {
 font-size: $font-size-h1;
}`;

export const htmlTagDontCS = `<p class=“large-text”>This is a large header</p>
<p class=“regular-text”>This is a large header</p>

.large-text {
 font-size: $font-size-h1;
}

.regular-text {
 font-size: $font-size-body;
}`;

export const htmlFlexExCS1 = `<div class=“side-by-side”>
<p>This is one</p>
<p>This is two</p>
<p>This is three</p>
<p>This is four</p>
<p>This is five</p>
<div>`;

export const htmlFlexExCS2 = `.side-by-side {
    display: flex;
   
    > p {
     flex: 1; // for equal sizing
    }
   }`;

export const htmlFlexExCS3 = `.side-by-side {

    > p {
     display: inline-block;
    }
   
   }`;

export const CSSStyleDoCS = `<div class=“box red”>Button Text</button>
   <div class=“box blue”>Button Text</button>
   <div class=“box orange”>Button Text</button>
   
   .box {
     color: $color-white;
     padding: $padding-small;
   
     &.red {
       background-color: $color-red;
     }
   
      &.blue {
       background-color: $color-blue;
     }
   
      &.orange {
       background-color: $color-orange;
     }
   }`;

export const CSSStyleDontCS = `<div class=“box-red”>Button Text</button>
   <div class=“box-blue”>Button Text</button>
   <div class=“box-orange”>Button Text</button>
   
     .box-red {
       color: $color-white;
       padding: $padding-small;
       background-color: $color-red;
     }
   
     .box-blue {
       color: $color-white;
       padding: $padding-small;
       background-color: $color-blue;
     }
   
     .box-orange {
       color: $color-white;
       padding: $padding-small;
       background-color: $color-orange;
     }`;

export const nestCSSDo = `<div class=“parent1”>
      <button>Don't press me!</button>
     </div>
     <div class=“parent2”>
      <p class=“text”>Hello World!</p>
      <button>Press me!</button>
     </div>
     <div class=“parent3”></div>
     
     button {
      height: 50px;
      border: solid 1px black;
    }
    
    .parent1 {
        background-color: pink;
        button {
          color: red;
        }
    }
    
    .parent2 {
      background-color: blue;
      .text {
        color: orange;
      }
      button {
        color: green;
      }
    }
    
    .parent3 {
      background-color: fuschia;
    }`;

export const nestCSSDont = `<div class=“parent1”>
    <button class=“badButton”>Don't press me!</button>
   </div>
   <div class=“parent2”>
    <p class=“text”>Hello World!</p>
    <button class=“goodButton”>Press me!</button>
   </div>
   <div class=“parent3”></div>
   
   .badButton {
    color: red;
  }
  
  .goodButton {
    color: green;
  }
  
  .text {
    color: orange;
  }
  
  .parent1 {
    background-color: pink;
  }
  
  .parent2 {
    background-color: blue;
  }
  
  .parent3 {
    background-color: fuchsia;
  }`;

export const mobileCSSDo = `.content {
    border: solid 5px brown;
    width: 100%;

    .text {
      text-align: center;
    }
  }

  /* Media Queries */
  // Tablet
  @media only screen and (min-width:$tablet-min-breakpoint) and (max-width:$tablet-max-breakpoint) {
    .content {
      border-width: 2px;
      .text {
        text-align: left;
      }
    }
  }
  
  // Desktop
  @media only screen and (min-width:$desktop-min-breakpoint) {
    .content {
      width: 980px;
    }
  }`;

export const mobileCSSDont = `.content {
    border: solid 5px brown;
    width: 100%;

    // tablet
    @media only screen and (min-width:$tablet-min-breakpoint) {
      border-width: 2px;
    }

    // desktop
    @media only screen and (min-width:$desktop-min-breakpoint) {
      width: 980px;
    }

    .text {
      text-align: center;

      // tablet
      @media only screen and (min-width:$tablet-min-breakpoint) {
        text-align: left;
      }
    }
  }
`;
