import * as base from "./lib/baseActions";
import useSWR from 'swr';
import { mutate } from 'swr';

export interface UploadFileResultType {
  id: string;
  userId: number;
  title: string;
  text: string;
}

const getEncryptedContent = async (): Promise<UploadFileResultType[]> => {
  try {
    const result = await base.get(`users/me/encryptedContent`);
    return result.data;
  } catch (err) {
    console.log('failed to get uploaded file:', err);
    throw new Error(`failed to get uploaded file`);
  }
};


type useGetEncryptedContent = [
  string
]

export const revalidateEncryptedContent = (id?: string) => {
  mutate(`users/me/encryptedContent`);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useGetEncryptedContent = () => {
  return useSWR<UploadFileResultType[]>('users/me/encryptedContent', getEncryptedContent);
};

const createEncryptedContent = async (title: string, text: string) => {
  try {	   
    const result = await base.post(`users/me/encryptedContent`, {
      title,
      text
    });

    revalidateEncryptedContent();

    return result.data;
  } catch(err) {
    console.log(err);
  } 
};

const updateEncryptedContent = async (contentId: number, title: string, text: string) => {
  try {	   
    const result = await base.put(`users/me/encryptedContent/${contentId}`, {
      title,
      text
    });

    revalidateEncryptedContent(`${contentId}`);
      
    return result.data;
  } catch(err) {
    console.log(err);
  } 
};

const deleteEncryptedContent = async (contentId: number) => {
  try {	   
    const result = await base.del(`users/me/encryptedContent/${contentId}`);

    revalidateEncryptedContent();

    return result.data;
  } catch(err) {
    console.log(err);
  } 
};

export {
  getEncryptedContent,
  createEncryptedContent,
  updateEncryptedContent,
  deleteEncryptedContent
};